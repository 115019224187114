import {MaterialSettingsTabs} from '@hconnect/common/enums'
import {useMemo} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {HeaderTab} from '../../../../../common/components/HeaderTabs'
import {NewMaterialFormDefaultValues} from '../hooks/useNewMaterialForm'

export const useNewMaterialTabs = (
  newMaterialForm: UseFormReturn<NewMaterialFormDefaultValues>
): HeaderTab[] => {
  const {t} = useTranslation()

  const {
    formState: {errors}
  } = newMaterialForm

  return useMemo(
    () => [
      {
        value: MaterialSettingsTabs.General,
        label: t(`materialsSettings.${MaterialSettingsTabs.General}`),
        disabled: false,
        hasError: !!(errors.type || errors.name || errors.source)
      },
      ...(process.env.REACT_APP_STAGE === 'qa'
        ? [
            {
              value: MaterialSettingsTabs.Classification,
              label: t(`materialsSettings.${MaterialSettingsTabs.Classification}`),
              disabled: true,
              hasError: false
            }
          ]
        : []),
      {
        value: MaterialSettingsTabs.SapCodes,
        label: t(`materialsSettings.${MaterialSettingsTabs.SapCodes}`, {
          amount: 0
        }),
        disabled: true,
        hasError: false
      },
      {
        value: MaterialSettingsTabs.LimsCodes,
        label: t(`materialsSettings.${MaterialSettingsTabs.LimsCodes}`, {
          amount: 0
        }),
        disabled: true,
        hasError: false
      }
    ],
    [t, errors.type, errors.name, errors.source]
  )
}
