import {
  MaterialSource,
  MaterialWithRecipes,
  ClassificationDTO,
  Product,
  LimsMaterial,
  EditMaterialDTO,
  Recipe
} from '@hconnect/common/types'
import {UseFormReturn} from 'react-hook-form'

import {useUrlParam} from '../../../../../routing'
import {useEditMaterial, useEditMaterialAttribute, useDeleteGlobalMaterial} from '../../../../hooks'
import {hasMaterialFormObjectFieldChanged} from '../helpers'

import {MaterialFormDefaultValues} from './useMaterialFormDefaultValues'

import {MaterialApiKeys} from '@settings/api/mutations'

export const useMaterialFormSubmit = (
  material: MaterialWithRecipes,
  materialForm: UseFormReturn<MaterialFormDefaultValues>
) => {
  const plantCode = useUrlParam('plantCode')

  const {
    handleSubmit,
    reset,
    formState: {isDirty, dirtyFields}
  } = materialForm

  const {mutate: editMaterial} = useEditMaterial()
  // TODO <HCP-82759>: remove after classification tab is implemented
  const {mutate: editMaterialAttribute} = useEditMaterialAttribute()
  const {mutate: deleteGlobalMaterial} = useDeleteGlobalMaterial()

  return handleSubmit((params) => {
    const {
      name,
      description,
      globalMaterial,
      source,
      boughtFrom,
      classification,
      products,
      limsMaterials,
      recipes,
      pxTrendCounters
    } = params

    if (!isDirty) return

    const materialBoughtFrom = source === MaterialSource.ProducedInPlant ? undefined : boughtFrom

    const editMaterialDto: EditMaterialDTO = {
      name,
      description,
      source,
      boughtFrom: materialBoughtFrom
    }

    if (hasMaterialFormObjectFieldChanged(dirtyFields.classification)) {
      editMaterialDto.classification = Object.fromEntries(
        Object.entries(classification).filter(([, value]) => value !== null)
      ) as ClassificationDTO
      editMaterialDto.classification.materialType = material.type
    }

    // Note: since we can update SAP codes tab from General tab by using setValue()
    // when "Produced" checkbox is toggled --> we also have to check if dirtyFields.products is boolean
    if (
      (typeof dirtyFields.products === 'boolean' && dirtyFields.products === true) ||
      dirtyFields.products?.some((field) => hasMaterialFormObjectFieldChanged(field))
    ) {
      editMaterialDto.products = products as Product[]
    }

    if (dirtyFields.limsMaterials?.some((field) => hasMaterialFormObjectFieldChanged(field))) {
      editMaterialDto.limsMaterials = limsMaterials as LimsMaterial[]
    }

    if (dirtyFields.pxTrendCounters?.some((field) => hasMaterialFormObjectFieldChanged(field))) {
      editMaterialDto.pxTrendCounters = pxTrendCounters.map(({name}) => name)
    }

    // Note: since we can update Recipes tab from General tab by using setValue()
    // when "Produced" checkbox is toggled --> we also have to check if dirtyFields.recipes is boolean
    const haveRecipesChangedFromProducedCheckbox =
      typeof dirtyFields.recipes === 'boolean' && dirtyFields.recipes === true

    const haveRecipesChanged =
      haveRecipesChangedFromProducedCheckbox ||
      dirtyFields.recipes?.some((recipeField) => {
        const hasRecipeChanged = hasMaterialFormObjectFieldChanged(recipeField)
        const haveRecipeComponentsChanged = recipeField.components?.some((recipeComponentField) => {
          const hasRecipeComponentChanged = hasMaterialFormObjectFieldChanged(recipeComponentField)
          const hasComponentMaterialChanged = hasMaterialFormObjectFieldChanged(
            recipeComponentField.material
          )
          return hasRecipeComponentChanged || hasComponentMaterialChanged
        })
        return hasRecipeChanged || haveRecipeComponentsChanged
      })

    if (haveRecipesChanged) {
      editMaterialDto.recipes = recipes as Recipe[]
    }

    editMaterial(
      {
        plantCode,
        materialId: material.id,
        dto: editMaterialDto
      },
      {
        onError: () => reset(),
        onSuccess: () => {
          // TODO <HCP-82759>: remove after classification tab is implemented
          if (hasMaterialFormObjectFieldChanged(dirtyFields.globalMaterial)) {
            if (globalMaterial !== null)
              editMaterialAttribute(
                {
                  key: MaterialApiKeys.globalMaterial,
                  plantCode,
                  materialId: material.id,
                  globalMaterialId: Number(globalMaterial.id)
                },
                {
                  onError: () => reset()
                }
              )
            else
              deleteGlobalMaterial(
                {plantCode, materialId: material.id},
                {
                  onError: () => reset()
                }
              )
          }
          reset(params)
        }
      }
    )
  })
}
