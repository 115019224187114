import {Status, UnitType} from '../enums'

import {Unit} from './kpiEntries'

export type Group = {
  id: string
  name: string
  status: Status
  groups?: Group[]
  hideGroups?: boolean
}

export type CategoriesTreeResponse = {
  businessAreas: Group[]
}

export interface Value {
  date: string
  version: number
  value?: string
  comments?: number
  kpiValueId: string
}

export type CommentsRequest = {
  kpiValueId: string
  kpiName: string
  unitName: string
}

export type ResourceDetailsResponse = {
  specificKpiId: string
  name: string
  unit: ResourceUnit
  kpiGroup: KpiGroup
  values: Value[]
}[]

export type ResourceDetail = {
  specificKpiId: string
  name: string
  unit: ResourceUnit
  kpiGroup: KpiGroup
  value: string
  version: number
  date: string
}

export type ResourceDetails = Record<string, ResourceDetail>

export type EditResourceDetails = Record<string, ResourceDetails>

export type ResourceGroups = Record<string, ResourceDetailsResponse>

export interface ResourceUnit {
  id: UnitType
  unitName: string
  abbreviation: string
}

export interface KpiGroup {
  id: string
  name: string
  businessAreaTypeId: number
  businessAreaName: string
}

export interface MonthlyKpisDetails {
  name: string
  id: string
  children: MonthlyKpisDetails[]
  kpis?: Kpi[]
}

export interface Kpi {
  specificKpiId: string
  name: string
  unit: Unit
  kpiGroup: ResourceGroup
  values: KpiValue[]
}

export interface ResourceGroup {
  id: string
  name: string
  businessAreaTypeId: number
  businessAreaName: string
}

export interface KpiValue {
  date: string
  value?: string
  version: number
  kpiValueId: string
  comments: number
}
