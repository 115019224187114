import {
  MaterialSource,
  MaterialWithRecipes,
  MaterialType,
  MaterialSettingsTabs
} from '@hconnect/common/types'
import {useMemo} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {HeaderTab} from '../../../../../common/components/HeaderTabs'

import {MaterialFormDefaultValues} from './useMaterialFormDefaultValues'

interface AvailableHeaderTabsParams {
  materialWithRecipes: MaterialWithRecipes
  materialForm: UseFormReturn<MaterialFormDefaultValues>
  isLoading: boolean
}

export const useExistingMaterialTabs = ({
  materialWithRecipes,
  materialForm,
  isLoading
}: AvailableHeaderTabsParams): HeaderTab[] => {
  const {t} = useTranslation()

  const {
    formState: {
      errors: {
        type: typeError,
        name: nameError,
        source: sourceError,
        classification: classificationError,
        products: productsError,
        limsMaterials: limsMaterialsError,
        recipes: recipesError,
        pxTrendCounters: pxTrendCountersError
      }
    },
    watch
  } = materialForm

  const currentSource = watch('source')

  const isProducedOrBoughtAndProduced = [
    MaterialSource.ProducedInPlant,
    MaterialSource.BoughtAndProduced
  ].includes(currentSource)

  return useMemo(() => {
    const producedInPlantTabs: HeaderTab[] = [
      {
        value: MaterialSettingsTabs.Recipes,
        label: t(`materialsSettings.${MaterialSettingsTabs.Recipes}`, {
          amount: materialWithRecipes.recipes.length
        }),
        disabled: isLoading,
        hasError: !!recipesError
      },
      {
        value: MaterialSettingsTabs.PxTrendTags,
        label: t(`materialsSettings.${MaterialSettingsTabs.PxTrendTags}`, {
          amount: materialWithRecipes.pxTrendCounters?.length ?? 0
        }),
        disabled: isLoading,
        hasError: !!pxTrendCountersError
      }
    ]

    return [
      {
        value: MaterialSettingsTabs.General,
        label: t(`materialsSettings.${MaterialSettingsTabs.General}`),
        disabled: false,
        hasError: !!(typeError || nameError || sourceError)
      },
      ...(process.env.REACT_APP_STAGE === 'qa' &&
      materialWithRecipes.type !== MaterialType.NonReportedMaterials
        ? [
            {
              value: MaterialSettingsTabs.Classification,
              label: t(`materialsSettings.${MaterialSettingsTabs.Classification}`),
              disabled: isLoading,
              hasError: !!classificationError
            }
          ]
        : []),
      {
        value: MaterialSettingsTabs.SapCodes,
        label: t(`materialsSettings.${MaterialSettingsTabs.SapCodes}`, {
          amount: materialWithRecipes.products.length
        }),
        disabled: isLoading,
        hasError: !!productsError
      },
      {
        value: MaterialSettingsTabs.LimsCodes,
        label: t(`materialsSettings.${MaterialSettingsTabs.LimsCodes}`, {
          amount: materialWithRecipes.limsMaterials.length
        }),
        disabled: isLoading,
        hasError: !!limsMaterialsError
      },
      ...(isProducedOrBoughtAndProduced ? producedInPlantTabs : [])
    ]
  }, [
    t,
    isProducedOrBoughtAndProduced,
    materialWithRecipes,
    isLoading,
    typeError,
    nameError,
    sourceError,
    classificationError,
    productsError,
    limsMaterialsError,
    recipesError,
    pxTrendCountersError
  ])
}
